<script setup lang="ts">
defineProps<{
  title: string
}>()

const agencyStore = useAgencyStore()
</script>
<template>
  <h1 class="header-title">
    <span
      class="ornament"
      :class="{
        'tw-bg-wc-blue-400': !agencyStore.customizations?.pages?.auth?.ornament,
      }"
      :style="{
        backgroundColor: String(
          agencyStore.customizations?.pages?.auth?.ornament
        ),
      }"
    />
    {{ title }}
  </h1>
</template>
<style scoped>
.header-title {
  @apply tw-w-full;
  @apply tw-text-wc-gray;
  @apply tw-font-extrabold;
  @apply tw-mt-2;
  @apply tw-relative;
  @apply tw-mb-6;
  @apply tw-text-2xl;
}

h1 .ornament {
  @apply tw-content-[''];
  @apply tw-absolute;
  @apply tw-bottom-[-5px];
  @apply tw-left-0;
  @apply tw-inline-flex;
  @apply tw-w-10;
  @apply tw-h-[5px];
}
</style>
